import _ from "lodash";
import statistics from "../utils/statistics";

export class SalaryItems {
  constructor(salaryItems) {
    this.items = salaryItems;
    this.completedSalaryEntries = salaryItems.filter(
      each =>
        each.annual_bonus !== null &&
        each.annual_stock !== null &&
        each.annual_salary !== null
    );
    this.median = statistics.quantile(
      _.map(this.items, "annual_compensation"),
      50
    );
  }

  quantile(percentile) {
    return statistics.quantile(
      _.map(this.items, "annual_compensation"),
      percentile
    );
  }

  get estimatedBreakdown() {
    const median = statistics.average(
      _.map(this.completedSalaryEntries, "annual_compensation"),
      50
    );
    const medianAnnualBase = statistics.average(
      _.map(this.completedSalaryEntries, "annual_salary"),
      50
    );
    const medianAnnualStock = statistics.average(
      _.map(this.completedSalaryEntries, "annual_stock"),
      50
    );
    const medianAnnualBonus = statistics.average(
      _.map(this.completedSalaryEntries, "annual_bonus"),
      50
    );

    const baseAsPercentageOfTotal = medianAnnualBase / median;
    const stockAsPercentageOfTotal = medianAnnualStock / median;
    const bonusAsPercentageOfTotal = medianAnnualBonus / median;

    const overallMedian = this.median;
    return {
      estimatedBase: this._roundToTheNearest(
        baseAsPercentageOfTotal * overallMedian,
        100000
      ),
      estimatedStock: this._roundToTheNearest(
        stockAsPercentageOfTotal * overallMedian,
        100000
      ),
      estimatedBonus: this._roundToTheNearest(
        bonusAsPercentageOfTotal * overallMedian,
        100000
      )
    };
  }

  _roundToTheNearest(value, integer) {
    return Math.round(value / integer) * integer;
  }
}
