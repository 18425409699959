<template>
  <div>
    <hr class="yellow-rule" />
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h4 font-weight-bold primary--text">
            {{ $t("explore_salaries") }}
          </h1>
        </v-col>

        <v-col cols="12" class="d-flex justify-center justify-sm-end">
          <v-btn
            color="secondary"
            :to="{ path: 'submit-compensation-selection' }"
            dark
            rounded
          >
            <v-icon left dark large>mdi-plus</v-icon>
            {{ $t("add_compensation") }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- Basic stats -->
      <v-row>
        <!-- Median values -->
        <v-col cols="3" class="hidden-sm-and-down">
          <h3 class="text-subtitle-2 font-weight-bold">
            {{ $t("annual_compensation") }}({{ $t("median") }})
          </h3>
          <div
            v-if="overviewDashboard.median"
            class="text-h5 font-weight-bold mb-4 green--text"
          >
            <animated-number
              :value="overviewDashboard.median"
              :formatValue="displayAnimatedCompensationItem"
              :round="1"
              :duration="700"
            />
          </div>
          <div v-else>
            -
          </div>
          <h3
            class="text-subtitle-2 font-weight-bold primary--text text--lighten-4"
          >
            {{ $t("annual_base_salary") }}
          </h3>
          <div class="mb-2 ">
            {{ displayCompensationItem(overviewDashboard.estimatedBase) }}
          </div>
          <h3
            class="text-subtitle-2 font-weight-bold primary--text text--lighten-4"
          >
            {{ $t("annual_bonus") }}
          </h3>
          <div class="mb-2">
            {{ displayCompensationItem(overviewDashboard.estimatedBonus) }}
          </div>
          <h3
            class="text-subtitle-2 font-weight-bold primary--text text--lighten-4"
          >
            {{ $t("annual_stock") }}
          </h3>
          <div class="mb-4">
            {{ displayCompensationItem(overviewDashboard.estimatedStock) }}
          </div>
          {{ $t("count") }}: {{ filteredItems.length }}
        </v-col>
        <!-- Percentiles -->
        <v-col cols="2" class="hidden-sm-and-down">
          <h3 class="mb-2 text-subtitle-2 font-weight-bold">
            {{ $t("percentiles") }}
          </h3>
          <div class="mb-3">
            90th:
            {{ displayCompensationItem(percentiles.highestQuantile) }}
          </div>
          <div class="mb-3">
            75th:
            {{ displayCompensationItem(percentiles.upperQuantile) }}
          </div>
          <div class="mb-3">
            50th: {{ displayCompensationItem(percentiles.median) }}
          </div>
          <div class="mb-3">
            25th:
            {{ displayCompensationItem(percentiles.lowerQuantile) }}
          </div>
          <div>
            10th:
            {{ displayCompensationItem(percentiles.lowestQuantile) }}
          </div>
        </v-col>
        <!-- Chart -->
        <v-col cols="12" md="7">
          <salary-bar-chart
            :height="200"
            :labels="chartLabels"
            :datasetData="chartData"
          ></salary-bar-chart>
        </v-col>
      </v-row>
      <!-- Basic stats for mobile -->
      <v-row class="hidden-md-and-up">
        <v-col class="text-center" cols="12">
          <h3 class="text-subtitle-2 font-weight-bold">
            {{ $t("annual_compensation") }}({{ $t("median") }})
          </h3>
          <div class="font-weight-bold green--text">
            {{ displayCompensationItem(overviewDashboard.median) }}
          </div>
        </v-col>
        <v-col class="text-center" cols="4">
          <div class="subtitle-2">{{ $t("annual_base_salary") }}</div>
          <div>
            {{ displayCompensationItem(overviewDashboard.estimatedBase) }}
          </div>
        </v-col>
        <v-col class="text-center" cols="4">
          <div class="subtitle-2">{{ $t("annual_bonus") }}</div>
          <div>
            {{ displayCompensationItem(overviewDashboard.estimatedBonus) }}
          </div>
        </v-col>
        <v-col class="text-center" cols="4">
          <div class="subtitle-2">{{ $t("annual_stock") }}</div>
          <div>
            {{ displayCompensationItem(overviewDashboard.estimatedStock) }}
          </div>
        </v-col>
        <v-col class="text-center median-border" xs="6" sm="4" md="2">
          <div class="subtitle-2">{{ $t("count") }}</div>

          <div>{{ filteredItems.length }}</div>
        </v-col>
      </v-row>
      <!-- Filter dialog for mobile -->
      <v-row class="hidden-md-and-up" justify="center">
        <v-bottom-sheet v-model="showFilterDialog" hide-overlay>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" dark block v-bind="attrs" v-on="on">
              {{ $t("filters") }}
            </v-btn>
          </template>
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showFilterDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <v-toolbar-title>{{ $t("filters") }}</v-toolbar-title>
            </v-toolbar>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="search.company_name"
                    :label="$t('company_name')"
                    :placeholder="$t('company_name_example')"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="search.role"
                    :label="$t('role')"
                    :items="roles"
                    item-text="name"
                    item-value="name"
                    :return-object="false"
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-combobox
                    v-model="search.focus"
                    :label="$t('focus')"
                    :placeholder="$t('focus_example')"
                    :items="roleFocusList"
                    clearable
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="search.yoe_from"
                    :label="$t('years_of_experience')"
                    :placeholder="$t('from')"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="search.yoe_to"
                    :placeholder="$t('to')"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-bottom-sheet>
      </v-row>
      <!-- Filters -->
      <v-row class="hidden-sm-and-down">
        <v-col cols="12" sm="3" class="d-flex align-center">
          <v-text-field
            v-model="search.company_name"
            :label="$t('company_name')"
            :placeholder="$t('company_name_example')"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" class="d-flex align-center">
          <v-select
            v-model="search.role"
            :label="$t('role')"
            :items="roles"
            item-text="name"
            item-value="name"
            :return-object="false"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2" class="d-flex align-center">
          <v-combobox
            v-model="search.focus"
            :label="$t('focus')"
            :placeholder="$t('focus_example')"
            :items="roleFocusList"
            clearable
          ></v-combobox>
        </v-col>
        <v-col cols="6" sm="2" class="d-flex align-center">
          <v-text-field
            v-model="search.yoe_from"
            :label="$t('years_of_experience')"
            :placeholder="$t('from')"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="2" class="d-flex align-center">
          <v-text-field
            v-model="search.yoe_to"
            :placeholder="$t('to')"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Salary table component -->
      <v-row>
        <v-col cols="12">
          <salary-table
            @current-items="getFiltered"
            :options="{ itemsPerPage: 20 }"
            :search-query="search"
            :loading="loading"
            :salaries="salaries"
            class="mb-4"
          />
        </v-col>
      </v-row>

      <v-row class="mb-4" justify="center">
        <h2>{{ $t("call_to_add") }}</h2>
      </v-row>
      <v-row justify="center">
        <v-btn
          color="secondary"
          :to="{ path: 'submit-compensation-selection' }"
          large
          dark
          rounded
        >
          <v-icon left dark large>mdi-plus</v-icon>
          {{ $t("add_compensation") }}
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<i18n>
{
  "en": {
    "call_to_add": "Help others like you, add your own compensation!",
    "add_compensation": "Add your compensation",
    "submit_compensation": "Submit Compensation",
    "search_entries": "Search by company, role, focus, level, etc",
    "company_name": "Company",
    "company_name_example": "Google, LINE, etc",
    "percentiles": "Percentiles",
    "explore_salaries": "Explore Salaries",
    "filters": "Show Filters",
    "input_date": "Date",
    "location": "Location",
    "grade": "Grade/Level",
    "role": "Role",
    "focus": "Focus",
    "focus_example": "E.g. iOS",
    "years_at_company": "Years at Company",
    "years_of_experience": "Years of Experience",
    "from": "Min",
    "to": "Max",
    "verified_dialog_title": "This salary was verified with official documentation so you can be confident it is accurate",
    "verified_dialog": "Contributors have the option of sending in verification documents when they submit their information. Accepted documents include offer letters, change of compensation documents, payslips or tax withholding forms. {0}.",
    "verified": "Read more about verified salaries",
    "contribute_salaries": "Want to contribute, add yours!",
    "annual_compensation": "Total Compensation",
    "annual_base_salary": "Base Salary",
    "annual_bonus": "Bonus",
    "annual_stock": "Equity",
    "compensation_entries": "Entries",
    "range": "Range",
    "count": "# of Entries",
    "median": "Median"
  },
  "ja": {
    "add_compensation": "年収を追加",
    "submit_compensation": "年収を提出",
    "call_to_add": "皆さんの情報で、企業の年収をよりオープンに可視化しましょう！",
    "search_entries": "社名・職種・フォカースで検索する",
    "company_name": "企業名",
    "company_name_example": "Google、メルカリなど",
    "percentiles": "パーセンタイル",
    "explore_salaries": "給与検索",
    "filters": "絞り込み",
    "input_date": "追加日",
    "location": "所在地",
    "grade": "グレード・レベル",
    "role": "職種",
    "focus": "フォーカス",
    "focus_example": "iOSなど",
    "years_at_company": "勤続年数",
    "years_of_experience": "経験年数",
    "from": "下限",
    "to": "上限",
    "verified_dialog_title": "この給与は、正式な書類によって証明・認証済みのため、正確なデータであることが証明されています",
    "verified_dialog": "データをご提供頂ける場合、データの提出時に正式な書類を提出することが可能です。受領可能な種類は、オファーレーター、給与書類、給与明細票、源泉徴収票です。詳細は、 {0}を参照ください。",
    "verified": "認証済みの給与",
    "contribute_salaries": "あなたの情報も提供してエンジニアのパワーアップに貢献しましょう!",
    "additional_information": "補足情報",
    "gender": "性別：",
    "male": "男性",
    "female": "女性",
    "other": "その他",
    "unknown": "不明",
    "education_level": "最終学歴:",
    "age": "年齢：",
    "annual_compensation": "年収",
    "annual_base_salary": "基本給(年)",
    "annual_bonus": "賞与(年)",
    "annual_stock": "株式報酬(年)",
    "compensation_entries": "件",
    "median": "中央値",
    "range": "年収範囲",
    "count": "件"
  }
}
</i18n>

<script>
import api from "services/api";
import store from "store";
import SalaryBarChart from "components/SalaryBarChart.chart.vue";
import SalaryTable from "components/salary_table/SalaryTable.vue";
import _ from "lodash";
import { SalaryItems } from "../utils/salaryItemsObject";
import AnimatedNumber from "animated-number-vue";
import { displayCompensationItemMixin } from "../mixins/displayCompensationItemMixin";

export default {
  mixins: [displayCompensationItemMixin],
  components: {
    SalaryTable,
    SalaryBarChart,
    AnimatedNumber
  },
  props: { role: String },
  data: () => ({
    showFilterDialog: false,
    chartLabels: [],
    chartData: [],
    filteredItems: [],
    overviewDashboard: {
      median: null,
      estimatedBase: null,
      estimatedStock: null,
      estimatedBonus: null
    },
    percentiles: {
      highestQuantile: null,
      upperQuantile: null,
      median: null,
      lowerQuantile: null,
      lowestQuantile: null
    },
    median: null,
    salariesGroupedByYear: [],
    salaries: [],
    search: {
      role: null
    },
    roles: [],
    roleFocusList: [
      "iOS",
      "Android",
      "Mobile",
      "Web Frontend",
      "Fullstack",
      "Backend",
      "AI/ML",
      "Site Reliability(SRE)",
      "Security",
      "Devops",
      "Data",
      "Networking"
    ],
    expanded: [],
    dataTableOptions: {
      itemsPerPage: 20
    },
    pagination: {
      current_page: 1
    },
    link: true,
    loading: false
  }),
  created() {
    this.getJobRoles();
    this.getSalaries();
    if (this.role) {
      this.search.role = _.startCase(this.role);
    }
  },
  computed: {
    locale() {
      return store.getters.locale;
    }
  },
  watch: {
    "search.role"() {
      const newSlug = this.roles.find(role => role.name === this.search.role)
        .slug;
      this.$router.replace({
        query: this.search.role ? { role: newSlug } : null
      });
    }
  },
  methods: {
    getJobRoles() {
      api.getJobRoles().then(resp => {
        this.roles = resp.data.items;
        const urlRoleParam = this.$route.query.role;
        this.search.role = this.roles.find(
          role => role.slug === urlRoleParam
        ).name;
      });
    },
    displayAnimatedCompensationItem(value) {
      return this.displayCompensationItem(value);
    },
    getFiltered(filteredItems) {
      if (filteredItems) {
        this.filteredItems = filteredItems;
        this.setChartLabels(this.filteredItems);
        const salaryItems = new SalaryItems(this.filteredItems);
        const estimatedBreakdown = salaryItems.estimatedBreakdown;
        this.overviewDashboard.median = salaryItems.median;
        this.overviewDashboard.estimatedBase = estimatedBreakdown.estimatedBase;
        this.overviewDashboard.estimatedBonus =
          estimatedBreakdown.estimatedBonus;
        this.overviewDashboard.estimatedStock =
          estimatedBreakdown.estimatedStock;
        this.percentiles.highestQuantile = salaryItems.quantile(90);
        this.percentiles.upperQuantile = salaryItems.quantile(75);
        this.percentiles.median = salaryItems.quantile(50);
        this.percentiles.lowerQuantile = salaryItems.quantile(25);
        this.percentiles.lowestQuantile = salaryItems.quantile(10);
      }
    },
    setChartLabels(salaryEntries) {
      const salariesByTotalComp = _.groupBy(salaryEntries, function(n) {
        return Math.round(n.annual_compensation / 1000000) * 1000000;
      });

      const keys = _.keys(salariesByTotalComp);

      var i;
      var start = parseInt(keys[0]);
      var endValue = parseInt(keys[keys.length - 1]);
      var labelValues = [];
      for (i = start; i <= endValue && i <= 30000000; i += 1000000) {
        labelValues.push(i);
      }

      var chartData = [];
      _.forEach(labelValues, label => {
        if (salariesByTotalComp[label]) {
          chartData.push(salariesByTotalComp[label].length);
        } else {
          chartData.push(0);
        }
      });
      this.chartLabels = labelValues;
      this.chartData = chartData;
    },
    getSalaries(page = 1) {
      this.loading = "grey";
      const page_query = { page: page };
      const query = {
        ...page_query
      };
      api.getSalaries(query).then(
        resp => {
          this.salaries = resp.data.items;
          this.pagination = resp.data.meta;
          this.loading = false;
          this.setChartLabels(this.salaries);
        },
        () => {
          this.loading = false;
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables"; // used for media queries
</style>
