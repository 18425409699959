<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    datasetData: Array,
    labels: Array
  },
  mounted() {
    this.renderLineChart();
  },
  computed: {
    chartData: function() {
      return this.datasetData;
    },
    chartLabels: function() {
      return this.labels;
    }
  },
  methods: {
    renderLineChart: function() {
      this.renderChart(
        {
          labels: this.chartLabels,
          datasets: [
            {
              data: this.chartData,
              backgroundColor: "rgba(255, 146, 51, 1)"
            }
          ]
        },
        {
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              title: function(tooltipItem) {
                var label = tooltipItem[0].xLabel;
                return label / 1000000 + "M";
              }
            }
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  callback: function(value) {
                    return value / 1000000 + "M";
                  }
                }
              }
            ]
          }
        }
      );
    }
  },
  watch: {
    datasetData: function() {
      //this.renderChart(this.data, this.options);
      this.renderLineChart();
    }
  }
};
</script>
